import "./common.js";
import "./booking.js";
// import "./landing.js";
import axios from "axios";
import moment from 'moment';

const questionBtn = document.querySelector(".btn-question");

$(window).scroll(function(){
    if($(window).scrollTop() > 300) $('.question-box').addClass('on')
    else $('.question-box').removeClass('on')
});

function leftPad(value) {
    if (value >= 10) {
        return value;
    }

    return `0${value}`;
}

function toStringByFormatting(source, delimiter = "-") {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate() + 1);

    return [year, month, day].join(delimiter);
}

// 장/단기 숙박 문의
async function rentQuestion() {
    const rentForm = document.querySelector(".rent-form");

    try {
        await axios
            .post("/rent/store", rentForm)
            .then(function (res) {
                if (res.data.result === true) {
                    alert("장단기 숙박 문의가 접수되었습니다.\n상담원이 빠르게 연락드리겠습니다.");
                    location.href = "";
                } else {
                    alert("장단기 숙박 문의 접수에 실패했습니다.");
                }
            })
            .catch(function (error) {
                if (error.response.status === 422) {
                    alert(error.response.data.message);
                } else if (error.response.status === 500) {
                    alert("장단기 숙박문의 접수에 실패했습니다.");
                }
            });
    
    } catch (error) {
        console.log(error);
    }


}

if (questionBtn) {
    questionBtn.addEventListener('click', rentQuestion);
}

$(function () {

    let oneday = moment().format('YYYY-MM-DD');
    let popStr = localStorage.getItem('whiteMain') 
    popStr ? $('.main-popup').hide() : $('.main-popup').show()    
    // popStr ? moment().format('YYYY-MM-DD') > popStr ? $('.main-popup').show() : $('.main-popup').hide() : $('.main-popup').show()
    let mainpopChk = !popStr || popStr != "" && moment().format('YYYY-MM-DD') > popStr ? true : false
    
    $('.main-popup .today').click(() => {
        localStorage.setItem('whiteMain', oneday)
        mainpopChk = false;
        $('.main-popup').hide()
    })
    
    $(window).scroll(function(){
        let scroll = $(window).scrollTop();
        if (scroll >= 50) {
            $('.main-popup').hide()
        } else {
            mainpopChk ? $('.main-popup').show() : $('.main-popup').hide()
        }
    });
    
    const rent_sdate = document.getElementById('rent_sdate');
    const rent_edate = document.getElementById('rent_edate');
    const date = new Date();
   
    flatpickr("#evt_checkin, #evt_checkout", {
        minDate: "today",
        defaultDate: "today",
        disableMobile: "true",
    });

    $("#endDate").val(
        toStringByFormatting(
            new Date(date.getFullYear(), date.getMonth(), date.getDate())
        )
    );

    $(".form-show").click((e) => {
        $(".form-wrapper, .popup-top .popup-desc, .form-show").toggle();
    });

    if (rent_sdate) {
        // 장/단기 숙박 임대희망일 날짜 설정 (오늘)
        rent_sdate.value = date.toISOString().substring(0, 10);

        // 장/단기 숙박 퇴거예정일 날짜 설정 (내일)
        date.setDate(date.getDate()+1);
        rent_edate.value = date.toISOString().substring(0, 10);
    }
    
});

