import moment from 'moment';
import jQuery from "jquery";
import axios from "axios";
window.$ = jQuery;

const reserveBtn = document.querySelector('#membership_btn');

const formCheck = ($form) => {
    const $input = $form.find('input, select').toArray();
    let aaa = 1;

    if ($form) {
        $($input).each((i,v) => {
            if(v.value === '' && $(v).data('optional') != "skip") {
                alert($(v).data('check'));
                $(v).focus();
                aaa = 99;
                return false;
            }
        });
    } 

    return aaa;
}

async function membership() {
    const $form = $('#memform');
    const ipts = document.querySelector('#memform');
    const aa = formCheck($form);
    
    if(aa < 99) {
        try {
            await axios
                .post('/send', ipts)
                .then(function (res) {
                    if (res.status === 200) {
                        alert("예약 접수가 완료되었습니다.\n상담원이 빠르게 연락드리겠습니다.");
                        location.href = "";
                    } else {
                        alert("예약 접수가 실패했습니다.1");
                        console.log(res);
                    }
                })
                .catch(function (error) {
                    alert("예약 접수가 실패했습니다.2");                 
                });
        
        } catch (error) {
            alert("예약 접수가 실패했습니다.3");
        }
    }   
}

if (reserveBtn) {
    reserveBtn.addEventListener("click", membership);
}

$(function() {
    // 멤버십 팝업
    /* $('#membership_btn').click(() => {
        let $form = $('#memform');
        formCheck($form)
    }) */

    flatpickr(".chkin", {
        minDate: new Date(),
        defaultDate: new Date(),
        disableMobile: "true",
        locale: "ko",
    });
    
    flatpickr(".chkout", {
        minDate: new Date().fp_incr(1),
        defaultDate: new Date().fp_incr(1),
        disableMobile: "true",
        locale: "ko",
    });
    
     $(".chkin").change((e) => {
        let ckinDate = $(e.currentTarget).val()
        let $ckout = $(e.currentTarget).next()
        let ckoutDate = moment(ckinDate).add(1, "days").format('YYYY-MM-DD')
        flatpickr($ckout, {
            minDate: ckoutDate,
            defaultDate: ckoutDate,
            disableMobile: "true",
            locale: "ko",
        })
    })
    $('.it-wrap .it').click((e) => {
        $('.it-wrap .it').removeClass('active')
        $(e.currentTarget).addClass('active')
        $('input[name=type]').val($(e.currentTarget).data('type'))
    })


    $("input[type=checkbox]").click((e) => {
        $(e.currentTarget).val($(e.currentTarget).is(':checked') ? 'y' : '')
    })
    $("#selectCk").on("change", () => {
        let disabled = $("#selectCk option:selected").data('disabled');
        let $input = $('#memform').find('input, select, textarea');
        $.each($input, (i,v) =>  $(v).attr('disabled', $(v).attr('id') === 'selectCk' || !disabled ? false : true))
        if(disabled){
alert(`[평창 더화이트]
안녕하세요.
평창 더화이트호텔 입니다.

저희 자체 내 사정으로 당분간 멤버십 예약 진행이 불가합니다.

[문의번호]
02-2139-7570

감사합니다.`)
        }
    });
    
});